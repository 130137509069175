import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Double Bounce Back Squats 4×6\\@50-60%1RM`}</p>
    <p>{`Single Leg BLB on Ball 4×6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`For Time:`}</p>
    <p>{`30-Alternating Back Rack Reverse Lunges (coming out of the rack, 15/leg)
115/75 (RX+ 135/95)`}</p>
    <p>{`40-Med Ball (squat) Cleans 20/14`}{`#`}{` (RX+ 30/20)`}</p>
    <p>{`50-Burpees`}</p>
    <p>{`60-Calorie Ski Erg`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free Partner WOD at 9:00 & 10:15am so bring a
friend! If you aren’t a member but would like to sign up please contact:
Daniel\\@crossfittheville.org`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      